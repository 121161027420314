
<div id="Welcome">
  <!---Start navigation Bar -->
  <nav class="navbar navbar-expand-lg navbar fixed-top  navbar-light bg-light">
   <a class="navbar-brand" href="#Welcome">
      <img src="assets/realislogo.png" width="50" height="40" class="d-inline-block" alt=""> Reali's Fine Italian Cuisine
   </a>
   <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarText" aria-controls="navbarText" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
   </button>
    <div class="collapse navbar-collapse" id="navbarText">
      <ul class="navbar-nav ml-auto">
          <li class="nav-item">
            <a class="nav-link" href="#Welcome">Welcome</a>
          </li>
        <li class="nav-item">
          <a class="nav-link" href="#About">About</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" href="#Menu">Menus</a>
        </li>
        <!-- <li class="nav-item">
          <a class="nav-link" href="#GFMenu">Gluten Free Menu</a>
        </li> -->
        <!-- <li class="nav-item">
          <a class="nav-link" href="#Reservation">Reservation</a>
        </li> -->
        <li class="nav-item">
          <a class="nav-link" href="#Hours">Hours</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" href="#OurLocation">Our Location & Reservations</a>
        </li>
       
      </ul>
  </div>
</nav>
<!--- End Navigation Bar -->
<!--- Start Carousel -->
<div id="carouselExampleIndicators" class="carousel slide" data-ride="carousel">
  <ol class="carousel-indicators">
    <li data-target="#carouselExampleIndicators" data-slide-to="0" class="active"></li>
      <li data-target="#carouselExampleIndicators" data-slide-to="1"></li>
      <li data-target="#carouselExampleIndicators" data-slide-to="2"></li>
      <li data-target="#carouselExampleIndicators" data-slide-to="3"></li>
  </ol>
  <div class="carousel-inner">
    <div class="carousel-item active">
        <img class="d-block w-100 img-fluid img-slider" src="assets/realisoutside.jpg" alt="First slide">
        <div class="carousel-caption">
          <h2>Welcome!</h2>
        <p>...</p>
      </div>
      </div>
      <div class="carousel-item">
        <img class="d-block w-100 img-fluid img-slider" src="assets/cal.JPEG" alt="Second slide">
        <div class="carousel-caption">
        <h2>Local Favorites</h2>
      <p>...</p>
    </div>
    </div>
      <div class="carousel-item">
          <img class="d-block w-100 img-fluid img-slider" src="assets/table.JPEG" alt="Third slide">
          <div class="carousel-caption">
          <h2>Traditional Italian Cuisine</h2>
        <p>...</p>
      </div>
      </div>
      <div class="carousel-item">
        <img class="d-block w-100 img-fluid img-slider" src="assets/bar2.JPEG" alt="Fourth slide">
        <div class="carousel-caption">
      <p>...</p>
    </div>
    </div>
  </div>
  <a class="carousel-control-prev" href="#carouselExampleIndicators" role="button" data-slide="prev">
    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
      <span class="sr-only">Previous</span>
  </a>
  <a class="carousel-control-next" href="#carouselExampleIndicators" role="button" data-slide="next">
    <span class="carousel-control-next-icon" aria-hidden="true"></span>
      <span class="sr-only">Next</span>
  </a>
</div>
</div>
<!--- End of Carousel -->
<!--- About-->
<div class="container">
 <div class="row" id="About">
    <div class="col navMenu">
        <h2 class="text-center" >~ About ~</h2>
    </div>
  </div>
  <div class="row bg-light" >
    <div class="col-md-6">
      <h3>History</h3>
      <p><i>Family Owned and Operated Since 1997</i><br><br>Reali’s Fine Italian Cuisine’s chef Jim Reali assembles meats, pastas, and sauces into hearty Italian creations using cooking skills first cultivated in his youth.  Diners can choose between upscale veal, chicken, and pasta entrees or decide to dine from the sea with a wonderful selection of seafood dishes.  A side of pasta topped with Reali’s house-made marinara sauce pairs exquisitely with any entrée.  Specials change daily and feature unique dishes as well as common favorites, not to mention the delicious dessert options to finish off your experience.  Even stopping in for drinks and appetizers at Reali’s full bar will make your night a great one.  </p>
      <img class="img-fluid" src="assets/frame2.jpeg">
    </div>
    <div class="col-md-6" data-aos="fade-up">
      <br>
      <br>
      <br>
      <br>
      <img class="img-fluid" src="assets/drink.JPEG">
    </div>
  </div>
  <div class="row bg-light"><br></div>
  <div class="row bg-light">
    <div class="col-md-6 order-md-1 order-2" data-aos="fade-up">
      <br>
      <br>
      <br>
      <img class="img-fluid " src="assets/beet.jpeg">
    </div>
    <div class="col-md-6 order-md-12 order-1">
      <img class="img-fluid " src="assets/gio.jpeg">
    </div>
  </div>
  <!--- End of About -->
  <!--- Start of Menu-->
<div class="row" id="Menu">
  <div class="col navMenu">
        <h2 class="text-center" >~ Menu ~</h2>
        <div style="display: flex; justify-content: center;">
          <p>Specials are posted daily on </p><a class="social-icon" href="https://www.facebook.com/realisfineitaliancuisine"><i class="fab fa-facebook"></i></a>
        </div>
  </div>
</div>
<div class="row bg-light">
  <div class="col-md-4" data-aos="slide-up">
    <div class="card view zoom">
        <img class="card-img-top img-fluid " src="assets/zucchini.png">
        <div class="card-body">
          <h5 class="card-title">~ Appetizers ~</h5>
          <ul class="list-group list-group-flush">

           <li class="list-group-item"><b>Fried Mozzerella ~ $12.99</b><br>Three of our homeade triangle shaped mozzerella, lightly fried with our own house sauce.</li>
           <li class="list-group-item"><b>Bruschetta Bread ~ $12.99</b><br>A blend of cannellini beans, tomatoes, scallions, fresh basil, and spinach sauteed in olive oil, garlic, mozzerella, and Romano cheese over Italian toast.</li>
           <li class="list-group-item"><b>Stuffed Mushrooms ~ $12.99</b><br>Ritz cracker & crabmeat stuffing drizzled with Mornay sauce.</li>
           <li class="list-group-item"><b>Fried Calimari Italia ~ $16.99</b><br>Tender rings of Calamari battered and lightly fried, tossed with hot pepper rings,  sharp Provolone, and prosciutto.</li>
           <li class="list-group-item"><b>Garlic Shrimp ~ $12.99</b><br>Sauteed with scallions and artichokes in butter, wine, and fresh garlic over cold tomato wheels.</li>
           <li class="list-group-item"><b>Fried Zucchini Sticks ~ $12.99</b><br>Hand cut zucchini sticks fried and tossed with pecorino Romano cheese and served with a side of house sauce.</li>
           <li class="list-group-item"><b>Fried Calamari ~ $14.99</b><br>Tender rings of calamari battered and lightly fried, tossed with hot pepper rings.</li>
          </ul>
        </div>
    </div>
  </div>
  <div class="col-md-4" data-aos="slide-up">
    <div class="card">
        <img class="card-img-top img-fluid " src="assets/antipasto.png">
        <div class="card-body">
          <h5 class="card-title">~ Salads ~</h5>
          <ul class="list-group list-group-flush">
            <li class="list-group-item"><b>Insalatta ~ $9.99</b><br>A blend of mixed greens wiith tomatoes, cucumbers, pepperoncini peppers, onions, and olives.</li>
            <li class="list-group-item"><b>Antipasto ~ $15.99/large $19.99</b><br>Vegetables, Italian cold cuts, and sharp provolone cheese on a bed of iceberg lettuce, Romaine, and mixed field greens. <br><i>(Small or Large Available)</i></li>
            <li class="list-group-item"><b>Caesar Salad ~ $5.99/large $9.99</b><br>Romaine lettuce with our own Caesar dressing topped with Romano cheese and croutons.</li>
        </ul>
        </div>
    </div>
  </div>
  <div class="col-md-4" data-aos="slide-up">
    <div class="card">
        <img class="card-img-top img-fluid" src="assets/tracy.jpeg">
        <div class="card-body">
          <h5 class="card-title">~ Vitello ~</h5>
          <ul class="list-group list-group-flush">
            <li class="list-group-item"><b>Veal Vincenza ~ $29.99</b><br>Veal covered with ricotta cheese, mushrooms, mozzerella, and Romano cheese, topped with eggplant in our own house sauce.</li>
            <li class="list-group-item"><b>Veal Francaise ~ $27.99</b><br>Lightly battered veal medallions sauteed in a lemon butter wine sauce.</li>
            <li class="list-group-item"><b>Veal Parmigiana ~ $25.99</b><br>Breaded veal cutlet topped with mozzerella cheese and our own house sauce</li>
            <li class="list-group-item"><b>Veal Marsala ~ $27.99</b><br>Medallions sauteed with mushrooms and garlic finished with only the best Marsala sauce.</li>
            <li class="list-group-item"><b>Veal Parma ~ $29.99</b><br>Veal medallions topped with prosciutto, mushrooms, scallions, tomato, and mozzarella in a Marsala wine sauce baked to perfection.</li>
            <li class="list-group-item"><b>Veal Oscar ~ $28.99</b><br>Veal, asparagus and crab sealegs, topped with mornay sauce.</li>
            <li class="list-group-item"><b>Veal Saltimbocca ~ $29.99</b><br>Veal covered in mozzarella cheese with sautéed prosciutto and tomatoes sprinkled with bread crumbs, topped with a Moray sauce.</li>
            <li class="list-group-item"><b>Veal Tracy ~ $31.99</b><br>Tender veal covered with prosciutto and mushrooms baked in a brandy cream sauce.</li>
          </ul>
        </div>
    </div>
  </div>
</div>
<br>
<div class="row bg-light">
  <div class="col-md-4" data-aos="slide-up">
    <div class="card">
      <img class="card-img-top img-fluid" src="assets/steak2.jpeg">
        <div class="card-body">
          <h5 class="card-title">~ Carne ~</h5>
          <ul class="list-group list-group-flush">
            <li class="list-group-item"><b>Steak Alla Mama 16oz. ~ $36.99</b><br>Pan cooked rib eye with mushrooms and scallions in butter, wine, and garlic.</li>
          </ul>
        </div>
    </div>
  </div>
  <div class="col-md-4" data-aos="slide-up">
    <div class="card">
      <img class="card-img-top img-fluid" src="assets/chicken.jpeg">
        <div class="card-body">
          <h5 class="card-title">~ Pollo ~</h5>
          <ul class="list-group list-group-flush">
            <li class="list-group-item"><b>Chicken Asparagia ~ $26.99</b><br>Boneless chicken breast, asparagus spears and fresh parsley in an asparagus cream sauce.</li>
            <li class="list-group-item"><b>Chicken Cannelloni ~ $27.99</b><br>Boneless breast of chicken covered with ricotta cheese, spinach, mushrooms, mozzarella and Romano cheese, topped with our own house sauce.</li>
            <li class="list-group-item"><b>Chicken Luigi ~ $28.99</b><br>Boneless breast of chicken covered with buffalo mozzarella, tomato, bacon, and Ritz cracker crumbs In a divine Momay sauce.</li>
            <li class="list-group-item"><b>Chicken Marsala ~ $24.99</b><br>A boneless breast of chicken and mushrooms in a superior dry Marsala wine sauce. </li>
            <li class="list-group-item"><b>Chicken Parmigiana ~ $23.99</b><br>Chicken cutlet topped with mozzarella cheese and our house sauce. </li>
            <li class="list-group-item"><b>Chicken Fra Diavolo ~ $24.99</b><br>Chicken tenderloins, black olives and hot peppers rings in a spicy hot marinara sauce over linguine.</li>
            <li class="list-group-item"><b>Chicken Colavita ~ $25.99</b><br>Chicken tenderloins sautéed in garlic and olive oil with hot peppers rings, black olives, zucchini, roasted red peppers over a bed of linguine.</li>
            <li class="list-group-item"><b>Chicken Giovanni ~ $24.99</b><br>Chicken tenderloins, mushrooms, roasted red peppers, scallions and herbs in a butter wine sauce over linguine.</li>
            <li class="list-group-item"><b>Chicken Copone ~ $27.99</b><br>Boneless breasted chicken sautéed in a lemon butter wine sauce with diced Italian ham and red onion, topped with mozzarella cheese.</li>
            <li class="list-group-item"><b>Chicken Italia ~ $25.99</b><br>Boneless breasted chicken, sautéed with spinach, artichoke hearts, and roasted red peppers in a Marsala wine sauce, topped with belgioso sharp Provolone cheese.</li>
            <li class="list-group-item"><b>Chicken Oscar ~ $26.99</b><br>Boneless breasted chicken baked in the oven, with asparagus spears and crab meat, topped with a Mornay sauce.</li>
            <li class="list-group-item"><b>Chicken Milanese ~ $25.99</b><br>Boneless breasted chicken sautéed with artichoke hearts, roasted red peppers, mushrooms, and scallions, in a white, wine garlic Romano sauce. </li>
          </ul>
        </div>
    </div>
  </div>
  <div class="col-md-4" data-aos="slide-up">
    <div class="card">
        <img class="card-img-top img-fluid" src="assets/sole.jpeg">
        <div class="card-body">
          <h5 class="card-title">~ Pesce ~</h5>
          <ul class="list-group list-group-flush">
            <li class="list-group-item"><b>Shrimp Scampi ~ $25.99</b><br>Shrimp sautéed with roasted red peppers, scallions, garlic and spices in a butter wine sauce over linguine.</li>
            <li class="list-group-item"><b>Shrimp Fra Diavolo ~ $25.99</b><br>A spicy marinara sauce with a combination of shrimp, olives and hot pepper rings over linguine.</li>
            <li class="list-group-item"><b>Shrimp Alfredo ~ $25.99</b><br>Shrimp in a seafood Alfredo sauce over linguine, garnished with black pepper and parsley.</li>
            <li class="list-group-item"><b>Baked Scrod ~ $18.99</b><br>Fresh scrod baked with butter and a cracker crumb dressing.</li>
            <li class="list-group-item"><b>Shrimp & Chicken Fantasia ~ $31.99</b><br>Shrimp and chicken with spinach, prosciutto, pepperoncini peppers and pignoli nuts with provolone cheese sautéed in garlic, pepper, olive oil and white wine over penne. </li>
            <li class="list-group-item"><b>Shrimp & Chicken Lorenzo ~ $29.99</b><br>Shrimp and chicken sautéed with prosciutto and tomato in a butter wine garlic sauce, blanketed with mozzarella cheese, served over penne.</li>
            <li class="list-group-item"><b>Sole Francaise ~ $26.99</b><br>Pan fried sole in a butter lemon wine sauce.</li>
            <li class="list-group-item"><b>Salmon Samerillo ~ $26.99</b><br>Salmon baked in the oven with mozzarella cheese and Ritz cracker crumbs, topped with diced tomato, pignoli nuts, fresh basil, olive oil, and a balsamic fig reduction. </li>
            <li class="list-group-item"><b>Veal and Shrimp Mediterranean ~ $32.99</b><br>Shrimp and veal medallions sautéed with black olives, mushrooms, artichoke hearts, scallions. garlic, olive oil, white wine, and finished with pepperoncini peppers. </li>
          </ul>
        </div>
    </div>
  </div>
</div>
<br>
<div class="row bg-light">
  <div class="col-md-4" data-aos="slide-up">
    <div class="card">
      <img class="card-img-top img-fluid" src="assets/tort.JPG">
        <div class="card-body">
          <h5 class="card-title">~ Pasta ~</h5>
          <ul class="list-group list-group-flush">
            <li class="list-group-item"><b>Tortellini Alfredo ~ $21.99</b><br>Cheese filled tortellini with a perfect blend of cream, butter, and Romano cheese.</li>
            <li class="list-group-item"><b>Tortellini Carbonara ~ $23.99</b><br>Cheese filled tortellini, bacon and prosciutto in the best alfredo sauce in town.</li>
            <li class="list-group-item"><b>Jumbo Cheese Ravioli ~ $17.99</b><br>Served with our own house sauce.</li>
            <li class="list-group-item"><b>Spinach di Giorgio ~ $17.99</b><br>Fresh spinach and red roasted peppers sautéed in garlic and olive oil topped with long horn Provolone cheese over Penne pasta.</li>
            <li class="list-group-item"><b>Linguini Aglio e Olio ~ $17.99</b><br>A blend of scallions, roasted red peppers, black olives, Romano cheese, garlic and olive oil.</li>
            <li class="list-group-item"><b>Ravioli Cristina ~ $22.99</b><br>Served with marinara, Romano cheese, bacon, and red onion.</li>
            <li class="list-group-item"><b>Linguini or Penne with Sauce ~ $13.99</b><br>Option to add Meatballs $21.99</li>
            <li class="list-group-item"><b>Linguini with Red or White Clam Sauce ~ $22.99</b><br>Simmered to perfection.</li>
            <li class="list-group-item"><b>Eggplant Cannelloni ~ $24.99</b><br>Eggplant covered with ricotta cheese, spinach, mushrooms, mozzarella, and Romano cheese, topped with our own house sauce.</li>
            <li class="list-group-item"><b>Eggplant Parmigiana ~ $19.99</b><br>Eggplant baked in the oven with mozzarella cheese in our own house sauce, topped with Pecorino Romano cheese. </li>
            <li class="list-group-item"><b>Penne Pink Alfredo ~ $19.99</b><br>Penne in a pink alfredo sauce with chopped asparagus spears. </li>
            <li class="list-group-item"><b>Linguini Alfredo ~ $19.99</b><br>Linguini in a creamy alfredo sauce garnished with black pepper and parsley.</li>
            <li class="list-group-item"><b>Penne Primavera ~ $18.99</b><br>Penne sautéed with zucchini, spinach, black olives, onion, mushrooms, and peppers</li>
            <li class="list-group-item"><b>Lobster Ravioli ~ $29.99</b><br>Jumbo lobster ravioli in a pink alfredo sauce.</li>
            <li class="list-group-item"><b>Gnocchi Alfredo Florentine ~ $24.99</b><br>Gnocchi sautéed in an alfredo sauce with spinach and artichoke hearts.</li>
          </ul>
        </div>
    </div>
  </div>
</div>
<!--- End of Menu -->
<!--- Start of GF Menu -->
<div class="row" id="GFMenu">
  <div class="col navMenu">
        <h2 class="text-center" >~ Gluten Free Menu ~</h2>
  </div>
</div>
<div class="row bg-light">
  <div class="col-md-4" data-aos="slide-up">
    <div class="card">
        <div class="card-body">
          <h5 class="card-title">~ Gluten Free Salads ~</h5>
          <ul class="list-group list-group-flush">
            <li class="list-group-item"><b>Insalatta ~ $5.50</b><br>A blend of mixed greens with tomatoes, cucumbers, pepperoncini peppers, onions, and olives.</li>
            <li class="list-group-item"><b>Antipasto ~ $9.99/large $14.99</b><br>Vegetables, Italian cold cuts, and sharp provolone cheese on a bed of iceberg lettuce, Romaine, and mixed field greens.</li>
            <li class="list-group-item"><b>Caesar Salad ~ $6.99</b><br>Romaine lettuce with our own Caesar dressing, topped with Romano cheese.</li>
          </ul>
        </div>
    </div>
  </div>
  <div class="col-md-4" data-aos="slide-up">
    <div class="card">
        <div class="card-body">
          <h5 class="card-title">~ Gluten Free Appetizers ~</h5>
          <ul class="list-group list-group-flush">
            <li class="list-group-item"><b>Garlic Shrimp ~ $9.99</b><br>Shrimp sautéed with scallions and artichokes in butter, wine, and fresh garlic over cold tomato wheels.</li>
            <li class="list-group-item"><b>Stuffed Cherry Peppers ~ $9.99</b><br>Hot cherry peppers stuffed with Italian ham and sharp Provolone cheese.</li>
          </ul>
        </div>
    </div>
  </div>
  <div class="col-md-4" data-aos="slide-up">
    <div class="card">
        <div class="card-body">
          <h5 class="card-title">~ Gluten Free Vitello ~</h5>
          <ul class="list-group list-group-flush">
            <li class="list-group-item"><b>Veal Parmigiana ~ $22.99</b><br>Breaded veal cutlet topped with mozzarella cheese and marinara sauce.</li>
            <li class="list-group-item"><b>Veal Pizziola ~ $24.99</b><br>Veal, mushrooms, scallions, and roasted red peppers in a tangy marinara, sprinkled with Romano cheese.</li>
            <li class="list-group-item"><b>Veal Tracy ~ $25.99</b><br>Tender veal covered with prosciutto and mushrooms, baked in a brandy cream sauce.</li>
          </ul>
        </div>
    </div>
  </div>
</div>
<br>
<div class="row bg-light">
  <div class="col-md-4" data-aos="slide-up">
    <div class="card">
        <div class="card-body">
          <h5 class="card-title">~ Gluten Free Pollo ~</h5>
          <ul class="list-group list-group-flush">
            <li class="list-group-item"><b>Chicken Parmigiana ~ $19.99</b><br>Chicken cutlet topped with mozzarella cheese and marinara sauce.</li>
            <li class="list-group-item"><b>Chicken Fra Diavolo ~ $22.99</b><br>Chicken tenderloins, black olives, and hot pepper rings in a spicy marinara over penne.</li>
            <li class="list-group-item"><b>Chicken Palermo ~ $22.99</b><br>Chicken tenderloins sautéed with Portabella mushrooms, tomatoes, black olives, and artichoke hearts in a butter, Romano, and garlic sauce over penne.</li>
            <li class="list-group-item"><b>Chicken Colavita ~ $22.99</b><br>Chicken tenderloins sautéed in garlic and olive oil with hot pepper rings, black olives, zucchini, and roasted red peppers over a bed of penne.</li>
          </ul>
        </div>
    </div>
  </div>
  <div class="col-md-4" data-aos="slide-up">
    <div class="card">
        <div class="card-body">
          <h5 class="card-title">~ Gluten Free Pesce ~</h5>
          <ul class="list-group list-group-flush">
            <li class="list-group-item"><b>Shrimp Scampi ~ $22.99</b><br>Shrimp sautéed with roasted red peppers, scallions, garlic, and spices in a butter wine sauce over penne.</li>
            <li class="list-group-item"><b>Shrimp Marinara ~ $21.99</b><br>Shrimp cooked in our marinara sauce served over penne.</li>
            <li class="list-group-item"><b>Shrimp Fra Diavolo ~ $22.99</b><br>A spicy marinara sauce with a combination of shrimp, olives, and hot pepper rings over penne. </li>
            <li class="list-group-item"><b>Shrimp Alfredo ~ $22.99</b><br>Shrimp in a light Alfredo sauce over penne garnished with black pepper and parsley. </li>
            <li class="list-group-item"><b>Baked Scrod ~ $17.99</b>Fresh scrod baked with butter.<br></li>
            <li class="list-group-item"><b>Scrod Cacciatore ~ $18.99</b>Fresh scrod, with roasted red peppers, mushrooms, and onions covered in marinara sauce baked to perfection. <br></li>
            <li class="list-group-item"><b>Shrimp & Chicken Roma ~ $24.99</b>A magnificent combination of shrimp, chicken, roasted red peppers, spinach, and artichoke hearts, cooked in a butter wine garlic sauce over penne. <br></li>
          </ul>
        </div>
    </div>
  </div>
  <div class="col-md-4" data-aos="slide-up">
    <div class="card">
        <div class="card-body">
          <h5 class="card-title">~ Gluten Free Carne ~</h5>
          <ul class="list-group list-group-flush">
            <li class="list-group-item"><b>Grilled Rib Eye 16-18 oz. ~ $28.99</b><br>Topped with mushrooms in a garlic butter sauce.</li>
          </ul>
        </div>
    </div>
  </div>
</div>
<br>
<div class="row bg-light">
  <div class="col-md-4" data-aos="slide-up">
    <div class="card">
        <div class="card-body">
          <h5 class="card-title">~ Gluten Free Pasta ~</h5>
          <ul class="list-group list-group-flush">
            <li class="list-group-item"><b>Penne Fra Diavolo ~ $16.99</b><br>Penne, black olives, and hot pepper rings in a spicy marinara sauce.</li>
            <li class="list-group-item"><b>Spinach Di Giorgio ~ $19.99</b><br>Fresh spinach and roasted red peppers sautéed in garlic and olive oil topped with sharp provolone cheese over penne pasta.</li>
            <li class="list-group-item"><b>Penne with Marinara Sauce ~ $12.99</b><br>Option to add sausage ($16.99).</li>
            <li class="list-group-item"><b>Penne with Red or White Clam Sauce ~ $18.99</b><br>Simmered to perfection.</li>
            <li class="list-group-item"><b>Sausage Portabellas ~ $21.99</b><br>Sliced sausage, green onions, portabella mushrooms, and sun-dried tomatoes sautéed in butter, wine, oil, and spices over penne. </li>
          </ul>
        </div>
    </div>
  </div>
</div>
<!--- End of Special Menu -->
<!--- Start of GF Menu -->
<div class="row" id="SpecialMenu">
  <div class="col navMenu">
        <h3 class="text-center" >~ Dinner For Two With a Bottle of Wine Menu ~</h3>
        <h3 class="text-center" >$39.99</h3>
  </div>
</div>
<div style="display: flex; justify-content: center;"><img style="width: 20%; height: 20%;" src="assets/wine.jpeg"></div>

<p style="text-align: center;"><strong>Wine Choice -&nbsp;</strong>Chardonnay, White Zinfandel, Merlot, Cabernet Sauvignon</p>
<p style="text-align: center;"><strong>Veal Parmigiana</strong></p>
<p style="text-align: center;">Breaded veal cutlet topped with mozzarella cheese in our own house sauce.</p>
<p style="text-align: center;"><strong>Chicken Fra Diavolo</strong></p>
<p style="text-align: center;">Chicken tenderloins, black olives, and hot pepper rings in a spicy hot marinara sauce over linguine.</p>
<p style="text-align: center;"><strong>Chicken Giovanni</strong></p>
<p style="text-align: center;">Chicken tenderloins, mushrooms, roasted red peppers, scallions, and herbs in a butter wine sauce over linguine.</p>
<p style="text-align: center;"><strong>Chicken Pizziola</strong></p>
<p style="text-align: center;">Chicken tenderloins, mushrooms, and black olives in a tangy marinara sauce served over linguine.</p>
<p style="text-align: center;"><strong>Chicken Parmigiana</strong></p>
<p style="text-align: center;">Chicken cutlet topped with mozzarella cheese in our own house sauce.</p>
<p style="text-align: center;"><strong>Chicken Marsala</strong></p>
<p style="text-align: center;">A boneless breast of chicken and mushrooms in a superior dry marsala wine sauce.</p>
<p style="text-align: center;"><strong>Chicken Colavita</strong></p>
<p style="text-align: center;">Chicken tenderloins sauteed in garlic and olive oil with hot pepper rings, black olives, zucchini, and roasted red peppers over a bed of linguine.</p>
<p style="text-align: center;"><strong>Chicken Cacciatore</strong></p>
<p style="text-align: center;">Chicken tenderloins, red peppers, mushrooms, and onions in marinara sauce over linguine.</p>
<p style="text-align: center;"><strong>Shrimp Scampi</strong></p>
<p style="text-align: center;">Shrimp sauteed with roasted red peppers, scallions, garlic, and spices in a butter wine sauce served over a bed of linguine.</p>
<p style="text-align: center;"><strong>Shrimp Marinara</strong></p>
<p style="text-align: center;">Shrimp cooked in our marinara sauce served over linguine</p>
<p style="text-align: center;"><strong>Shrimp Fra Diavolo</strong></p>
<p style="text-align: center;">Shrimp, olives, and hot pepper rings in a spicy marinara sauce served over linguine.</p>
<p style="text-align: center;"><strong>Baked Schrod</strong></p>
<p style="text-align: center;">Fresh baked with butter and a cracker crumb dressing.</p>
<p style="text-align: center;"><strong>Spinach di Giargio</strong></p>
<p style="text-align: center;">Fresh spinach and roasted red peppers sauteed in garlic and olive oil, topped with sharp provolone cheese over penne.</p>
<p style="text-align: center;"><strong>Penne Pink Alfredo</strong></p>
<p style="text-align: center;">Penne in a pink alfredo sauce with chopped asparagus spears</p>
<p style="text-align: center;"><strong>Linguine Aglio et Olio</strong></p>
<p style="text-align: center;">A blend of scallions, roasted red peppers, black olives, romano cheese, garlic, and olive oil.</p>
<p style="text-align: center;"><strong>Penne Primavera</strong></p>
<p style="text-align: center;">Penne sauteed with zucchini, spinach, black olives, onions, mushrooms, and peppers.</p>
<p style="text-align: center;"><strong>Linguine or Penne with Sauce</strong></p>
<p style="text-align: center;">With meatballs or sausage.</p>


<!--- End of Special Menu -->
<!-- - Start of Reservation-->
<!-- <div class="row" id="Reservation">
  <div class="col navMenu">
        <h2 class="text-center">~ Reservation ~</h2>
  </div>
</div>
<div class="row">
  <div class=" col-lg-12 reserve-container" data-aos="fade-up">
    <img class="img-fluid image-reserve" src="assets/images/reserve.jpg">
    <div class="reserve-text col-lg-12 ">
      <h1 class="text-center">Timetables</h1>
      <div class="row">
        <div class="col-6">
             <h2 class="text-center">Lunch</h2>
            <h5 class="text-center">12:00 - 15:00</h5>
        </div>
        <div class="col-6">
            <h2 class="text-center">Dinner</h2>
            <h5 class="text-center">19:30 - 23:30</h5>
        </div>
      </div>
    </div>
  </div>
</div>
<br>
<div class="row bg-light">
  <div class="col">
    <form>
      <div class="form-row">
          <div class="form-group col-6">
            <h3>Reserve</h3>
            <label for="inputDate"> Date</label>
            <input type="date" class="form-control" id="inputDate" placeholder="Data gg/mm/aaaa">
          </div>
          <div class="form-group col-6">
            <h3>Details</h3>
            <label for="inputName"> Name</label>
            <input type="text" class="form-control" id="inputName" placeholder="Name">
          </div>
          <div class="form-group col-6">
            <label for="inputTime"> Timetables</label>
            <input type="time" class="form-control" id="inputTime" placeholder="Timetables">
          </div>
          <div class="form-group col-6">
            <label for="inputEmail"> Email</label>
            <input type="email" class="form-control" id="inputEmail" placeholder="Email">
          </div>
          <div class="form-group col-6">
            <label for="inputNumber"> Number of Guests</label>
            <input type="number" class="form-control" id="inputNumber" placeholder="Number of Guests">
          </div>
          <div class="form-group col-6">
            <label for="inputCel"> Phone</label>
            <input type="tel" class="form-control" id="inputCel" placeholder="Phone">
          </div>
          <div class="form-group col-12">
            <label for="inputComment"> Further requests</label>
          <textarea class="form-control" rows="4" id="inputComment" placeholder="Further requests"></textarea>
        </div>
       </div>
      <div class="row">
          <div class="col-md-4 col-md-offset-4">
            <button type="submit" class="btn btn-secondary btn-block">Reserve</button>
          </div>
        </div>
    </form>
  </div>
</div> -->
<!--- End of Reserve -->
<!--- Start of Hours -->
<div class="row" id="Hours">
  <div class="col navMenu">
    <h2 class="text-center">~ Hours ~</h2>
  </div>
</div>
<div class="row">
  <div class="col-md-3">
    <p><b>Tuesday-Thursday</b><br>4:30pm - 9pm</p>
    <p><b>Friday & Saturday</b><br>4:30pm - 10pm</p>
  </div>
</div>
<!--- End of Hours -->
<!--- Start of Our Location -->
<div class="row" id="OurLocation">
  <div class="col navMenu">
    <h2 class="text-center">~ Our Location & Reservations~</h2>
  </div>
</div>
<div class="row">
  <div class="col-md-3">
    <h3>Address:</h3>
    <p>79 Putnam Pike</p>
      <p>Johnston, RI 02919</p>
    <h3>Phone Number:</h3>
    <p>401-231-3004</p>
    <p>All reservations are taken over the phone, call and make yours today!</p>
  </div>
</div>
<!--- End of Our Location -->
<div class="row footer bg-light">
  <div class="col">
    <p class="text-center">Follow us: 
      <a class="social-icon" href="https://www.facebook.com/realisfineitaliancuisine"><i class="fab fa-facebook"></i></a> 
      <a class="social-icon" href="https://www.instagram.com/realisjohnston401/"><i class="fab fa-instagram"></i></a></p>
  </div>
  <div class="col">
    <p class="text-center">Copyright &copy; Reali's Fine Italian Cuisine 2022</p>
  </div>
  <div class="col">
    <p class="text-center">Powered by: <a href="#">with love</a></p>
  </div>
</div>
</div>

<router-outlet></router-outlet>
